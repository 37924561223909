import React from 'react';
import { setSelectStatus } from 'app/entities/xtraho_services/form/form.reducer';
import { useAppDispatch } from 'app/config/store';
import { statusList } from 'app/config/entities-conf';
import { translate } from 'react-jhipster';

const StatusSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const statusSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setSelectStatus(event.target.value));
  };

  return (
    <div>
      <select name="status" id="status" className="filter-select filter-select--primary" onChange={statusSelection}>
        <option key="all" value="all">
          {translate('status.all')}
        </option>
        {statusList.map((status, i) => (
          <option key={status.value} value={status.value}>
            {status.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default StatusSelector;
