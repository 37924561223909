import 'react-toastify/dist/ReactToastify.css';
import './scss/main.scss';
import 'app/config/dayjs';

import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Wrapper from './Wrapper';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const App = () => {
  return (
    <BrowserRouter basename={baseHref}>
      <Wrapper />
    </BrowserRouter>
  );
};

export default App;
