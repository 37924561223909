export const entitiesTypes = [
  {
    name: 'indexes',
  },
];

export const statusList = [
  {
    label: 'Pending',
    value: 'analyzed',
  },
  {
    label: 'Work in progress',
    value: 'wip',
  },
];
