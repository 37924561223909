import React from 'react';
import Button from './Button';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { nextPage } from '../form/form.reducer';
import { translate, Translate } from 'react-jhipster';

function LoadMore() {
  const dispatch = useAppDispatch();

  let displayedEntities = useAppSelector(state => state.xtrahoui.form.entities);
  displayedEntities = displayedEntities.length;
  let totalEntities = useAppSelector(state => state.xtrahoui.form.totalItems);

  function selectButtonVisibility() {
    return displayedEntities < totalEntities;
  }

  const isVisible = selectButtonVisibility();

  function loadMore() {
    dispatch(nextPage());
  }

  return (
    <div className="text-center mt-2">
      {isVisible ? (
        <Button size="small" background="white" id="loadmore" symbol="add" title={translate('loadmore')} onClick={loadMore} />
      ) : (
        <p className="small text-muted">
          <Translate contentKey="nomore"></Translate>
        </p>
      )}
    </div>
  );
}

export default LoadMore;
