import React from 'react';
import Gauge from 'app/entities/xtraho_services/components/Gauge';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { partialUpdateEntity, skipEntity, updateEntity } from 'app/entities/xtraho_services/form/form.reducer';
import { dateFormatter, errorToastDisplay, percentageCalculation, successToastDisplay } from 'app/shared/util/entity-utils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Translate, translate } from 'react-jhipster';
import { LOCALE_FORMAT } from 'app/config/constants';
import ItemInput from './ItemInput';
import PdfViewer from './PdfViewer';

const locale = LOCALE_FORMAT;

function FormDetail() {
  const dispatch = useAppDispatch();
  let formEntity = useAppSelector(state => state.xtrahoui.form.entity);

  const globalConfidenceScore = useAppSelector(state => state.xtrahoui.form.entity.confidenceScore);

  // Recursive function to find a field in all the extractedData
  const findFieldRecursively = (data, fieldName) => {
    for (const key in data) {
      if (key === fieldName && data[key].hasOwnProperty('value')) {
        return data[key];
      } else if (typeof data[key] === 'object' && data[key] !== null) {
        const result = findFieldRecursively(data[key], fieldName);
        if (result) return result;
      }
    }
    return null;
  };

  /**
   * Change formEntity status on skip
   */
  const skip = () => {
    const entity = {
      id: formEntity.id,
      status: 'WIP',
    };

    dispatch(skipEntity(entity))
      .unwrap()
      .then(() => {
        successToastDisplay(translate('formSkipped'));
      })
      .catch(error => {
        errorToastDisplay(translate('formProblem') + ' ' + error);
      });
  };

  /**
   * Save the new value of a field when modified
   */
  const handleInput = e => {
    const entity = {
      id: formEntity.id,
      status: 'WIP',
      extractedData: {},
    };

    entity.extractedData[e.target.dataset.parent] = JSON.parse(JSON.stringify(formEntity.extractedData[e.target.dataset.parent]));

    entity.extractedData[e.target.dataset.parent].confidenceScore = -1;

    const field = findFieldRecursively(entity.extractedData, e.target.name);

    if (!field || field.value === e.target.value) {
      return;
    }

    field.value = e.target.value;
    if (field.hasOwnProperty('confidence')) {
      field.confidence = -1;
    }

    dispatch(partialUpdateEntity(entity))
      .unwrap()
      .then(() => {
        e.target.parentElement.classList.add('saved', 'saved--success');
        setTimeout(() => {
          e.target.parentElement.classList.remove('saved', 'saved--success');
        }, 2000);
      })
      .catch(error => {
        errorToastDisplay(translate('formProblem') + ' ' + error);
        e.target.parentElement.classList.add('saved', 'saved--error');
        setTimeout(() => {
          e.target.parentElement.classList.remove('saved', 'saved--error');
        }, 3000);
        e.target.value = formEntity.extractedData[e.target.name].value;
      });
  };

  const onSubmit = () => {
    const entity = JSON.parse(JSON.stringify(formEntity));
    let errorFields = [];
    for (const [key, val] of Object.entries(formEntity.extractedData) as any) {
      if (entity.extractedData[key].error) {
        errorFields.push(entity.extractedData[key].error);
      }
    }
    if (errorFields.length > 0) {
      errorToastDisplay('Unable to send the data. Please save correct values first.');
      return;
    }
    // TODO Call the API when available
    entity.status = 'PROCESSED';
    dispatch(updateEntity(entity))
      .unwrap()
      .then(() => {
        successToastDisplay(translate('formSent'));
      })
      .catch(error => {
        errorToastDisplay(translate('formProblem'));
      });
  };

  return (
    <div className="item-detail">
      <ToastContainer />
      {Object.keys(formEntity).length ? (
        <>
          <div className="item-detail__datas">
            <div className="item-detail__datas__wrapper">
              <div className="item-detail-header">
                <div className="item-detail-header__info">
                  <Gauge percentage={percentageCalculation(globalConfidenceScore)} size={'big'} />
                  <div className="item-detail-header__info__wrapper">
                    <div className="item-detail-header__data">
                      <span className="item-detail-header__date">{dateFormatter(formEntity.createdDate, locale)}</span>
                      <span className="item-detail-header__separator"> • </span>
                      <span className="item-detail-header__status">{formEntity.status}</span>
                    </div>
                    <h2>#{formEntity.id}</h2>
                  </div>
                </div>
                <div className="item-detail-header__actions">
                  <div className="item-detail-header__actions-secondary">
                    <button className="btn btn--second btn--small" onClick={onSubmit}>
                      <Translate contentKey="entity.action.send">Send</Translate>{' '}
                      <span className="icon material-symbols-outlined">verified</span>
                    </button>
                    <button className="btn btn--transparent btn--small" onClick={skip}>
                      <Translate contentKey="entity.action.skip">Skip</Translate>{' '}
                      <span className="icon material-symbols-outlined"> keyboard_tab</span>
                    </button>
                  </div>
                </div>
              </div>
              <form>
                <div className="item-detail-body">
                  <div className="item-detail-body__actions"></div>
                  {Object.values(formEntity.extractedData)
                    .sort((a: any, b: any) => a.name.localeCompare(b.name))
                    .map((item: any, index) => {
                      return (
                        <div
                          key={formEntity.id + index}
                          className={item.error !== null ? 'item-input item-input--error' : 'item-input'}
                          onBlur={e => handleInput(e)}
                        >
                          <div className="item-input__score">
                            <Gauge percentage={percentageCalculation(item.confidenceScore)} />
                          </div>
                          <div className="item-input__body">
                            <div className="item-input__element">
                              <label className="form-label" htmlFor={item.name}>
                                {item.name} <span className="saved__label saved__label--success">{translate('savedSuccess')}</span>{' '}
                                <span className="saved__label saved__label--error">{translate('savedError')}</span>
                              </label>
                              <ItemInput item={item} />
                            </div>
                            {item.error && <div className={`item-input__notice show`}>{item.error.message}</div>}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </form>
            </div>
          </div>
          <div className="item-detail__overview">
            <div className="item-detail__overview__wrapper">
              {formEntity.formImageContentType.startsWith('image/') ? (
                <div>
                  <img src={`data:${formEntity.formImageContentType};base64,${formEntity.formImage}`} alt="Document preview" />
                </div>
              ) : null}
              {formEntity.formImageContentType == 'application/pdf' ? (
                <PdfViewer base64String={formEntity.formImage} />
              ) : null}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default FormDetail;
