import React from 'react';
import ArrayField from './ArrayField';

const ItemInput = ({ item }) => {
  const itemvalue = item.value;
  if (typeof itemvalue !== 'object' || item.value == null) {
    return <input className="form-element" type="text" name={item.name} data-parent={item.name} defaultValue={item.value} id={item.name} />;
  } else {
    for (const property in itemvalue) {
      if (typeof itemvalue[property].value === 'object') {
        return <ArrayField array={item.value.map(t => t.value)} parent={item.name} />;
      } else {
        return (
          <input className="form-element" type="text" name={item.name} defaultValue={item.value} data-parent={item.name} id={item.name} />
        );
      }
    }
  }
};

export default ItemInput;
