import { createSlice } from '@reduxjs/toolkit';

interface StatusState {
  selectedStatus: string;
}

// Add a default selectedStatus to display the pending forms on component load
const initialState = { selectedStatus: 'all' } satisfies StatusState as StatusState;

const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    selectStatus(state, action) {
      state.selectedStatus = action.payload;
    },
  },
});

export const { selectStatus } = statusSlice.actions;
export default statusSlice.reducer;
