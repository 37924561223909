import React, { useState } from 'react';
import SideNavItem from './SideNavItem';
import { translate } from 'react-jhipster';
import { entitiesTypes } from 'app/config/entities-conf';

function SideNav() {
  const [types] = useState(entitiesTypes);
  const [activeItem, setActiveItem] = useState(types[0].name);

  function handleTypeClick(item: React.SetStateAction<string>) {
    setActiveItem(item);
  }

  return (
    <nav className="sidenav">
      <ul className="sidenav-menu">
        {types.map(type => (
          <SideNavItem
            key={type.name}
            type={translate(type.name)}
            icon="stacks"
            isActive={activeItem === type.name}
            onClick={() => handleTypeClick(type.name)}
          />
        ))}
      </ul>
    </nav>
  );
}

export default SideNav;
