import React from 'react';
import StatusSelector from './StatusSelector';
import { Translate } from 'react-jhipster';
import Upload from '../../../modules/upload/UploadButton';

function ItemsListHeader(props) {
  let wording = '';
  if (props.number <= 1) {
    wording = 'element';
  } else {
    wording = 'elements';
  }

  return (
    <div className="items-list__header">
      <div className="items-list__header__title">
        <h1>
          <Translate contentKey="indexes"></Translate>
        </h1>
        <div className="items-list__header__subtitle">
          {props.number} {wording} <Translate contentKey="togo"></Translate>
        </div>
      </div>
      <div className="items-list__filter">
        <StatusSelector />
      </div>
      <Upload />
    </div>
  );
}

export default ItemsListHeader;
