import React, { useId } from 'react';

const Gauge = ({ percentage, size = 'default' }) => {
  const uniqueId = useId();
  let state: string = 'null';

  if (percentage <= 50) {
    state = 'low';
  } else if (percentage <= 75) {
    state = 'medium';
  } else if (percentage <= 100) {
    state = 'high';
  }

  return percentage >= 0 ? (
    <span className={'score score--' + state + ' score--' + size}>
      <svg className="score__progress" style={{ '--percentage': percentage } as React.CSSProperties}>
        <defs>
          <mask id={'mask-' + uniqueId}>
            <circle className="fg"></circle>
          </mask>
        </defs>
        <foreignObject x="0" y="0" width="100%" height="100%" mask={'url(#mask-' + uniqueId + ')'}>
          <div className="bg"></div>
        </foreignObject>
      </svg>
      <span className="score__value">{percentage}</span>
    </span>
  ) : (
    <div className="score user-edit" title="Updated by user">
      <span className="material-symbols-outlined">person_edit</span>
    </div>
  );
};

export default Gauge;
