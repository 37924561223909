import React from 'react';

function Logo() {
  return (
    <svg id="logo" width="71" height="16" viewBox="0 0 71 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3_2688)">
        <path
          d="M21.7212 13.5411C21.5748 13.5682 21.4074 13.5817 21.2331 13.5817C20.996 13.5817 20.7798 13.5478 20.5846 13.4733C20.3893 13.3988 20.229 13.2701 20.1104 13.0737C19.9919 12.8705 19.9361 12.586 19.9361 12.2134V6.10331H22.3348V3.9492H19.9361V1.11093H17.0701V3.94243H15.3408V6.09654H17.0701V12.6672C17.0701 13.4056 17.2305 14.022 17.5583 14.5165C17.893 15.011 18.3532 15.3768 18.932 15.6071C19.5107 15.8374 20.1523 15.9458 20.8705 15.9255C21.2749 15.9119 21.6236 15.8781 21.9025 15.8171C22.1884 15.7561 22.4046 15.702 22.558 15.6478L22.0768 13.4666C22.0001 13.4869 21.8816 13.5072 21.7282 13.5343L21.7212 13.5411Z"
          fill="#121010"
        />
        <path
          d="M30.6397 3.77307C29.9424 3.77307 29.3218 3.96274 28.7779 4.33531C28.241 4.70787 27.8575 5.22946 27.6343 5.91363H27.5088V3.94242H24.7266V15.7629H27.5925V8.81287C27.5925 8.3116 27.711 7.86452 27.9481 7.47841C28.1852 7.09229 28.513 6.79424 28.9244 6.57747C29.3427 6.35394 29.8099 6.24555 30.3329 6.24555C30.577 6.24555 30.828 6.26587 31.086 6.29974C31.351 6.33361 31.5462 6.36748 31.6648 6.40813V3.84758C31.5323 3.82049 31.3649 3.80694 31.1627 3.79339C30.9675 3.77985 30.7932 3.77307 30.6328 3.77307H30.6397Z"
          fill="#121010"
        />
        <path
          d="M41.6852 4.64691C41.204 4.34208 40.6741 4.11854 40.0953 3.98306C39.5165 3.84759 38.9308 3.77985 38.345 3.77985C37.4943 3.77985 36.7203 3.90178 36.016 4.15241C35.3118 4.39627 34.719 4.75529 34.2309 5.24301C33.7428 5.72396 33.3942 6.32684 33.185 7.04488L35.8626 7.41744C36.0021 7.01101 36.281 6.65876 36.6854 6.36071C37.0969 6.06266 37.6547 5.91363 38.359 5.91363C39.0214 5.91363 39.5374 6.06943 39.8861 6.38781C40.2417 6.70618 40.4161 7.15326 40.4161 7.73582V7.78323C40.4161 8.04742 40.3115 8.25063 40.1092 8.37934C39.907 8.50127 39.5863 8.5961 39.14 8.65707C38.6937 8.71126 38.1149 8.779 37.4037 8.84674C36.811 8.9077 36.2392 9.00931 35.6813 9.14479C35.1304 9.28027 34.6354 9.47671 34.203 9.7409C33.7637 10.0051 33.422 10.3505 33.164 10.7909C32.913 11.2244 32.7805 11.7798 32.7805 12.4437C32.7805 13.2159 32.9548 13.873 33.3105 14.4014C33.6731 14.9297 34.1542 15.3294 34.7678 15.6003C35.3885 15.8645 36.0788 16 36.8528 16C37.4874 16 38.0382 15.9187 38.5124 15.7426C38.9866 15.5665 39.384 15.3429 39.6908 15.0584C40.0046 14.7739 40.2557 14.4623 40.43 14.1372H40.5276V15.7629H43.282V7.85097C43.282 7.0652 43.1356 6.4149 42.8357 5.88654C42.5428 5.36494 42.1593 4.95173 41.6782 4.64691H41.6852ZM40.423 11.5224C40.423 11.956 40.3045 12.3624 40.0744 12.7282C39.8443 13.1008 39.5235 13.392 39.0981 13.6224C38.6728 13.8459 38.1777 13.9543 37.5989 13.9543C37.0202 13.9543 36.5111 13.8256 36.1276 13.5614C35.7441 13.2972 35.5488 12.9111 35.5488 12.3963C35.5488 12.0373 35.6465 11.746 35.8417 11.5157C36.037 11.2853 36.3019 11.1025 36.6436 10.9737C36.9783 10.845 37.3618 10.7502 37.7942 10.696C37.9824 10.6689 38.2056 10.6418 38.4706 10.6012C38.7355 10.5605 38.9935 10.5267 39.2515 10.4793C39.5165 10.4318 39.7536 10.3776 39.9628 10.3167C40.179 10.2557 40.3324 10.188 40.423 10.1135V11.5089V11.5224Z"
          fill="#121010"
        />
        <path
          d="M54.9549 4.30144C54.3273 3.95597 53.5882 3.78662 52.7444 3.78662C51.817 3.78662 51.05 3.98306 50.4433 4.36918C49.8436 4.75529 49.4043 5.27688 49.1184 5.9475H48.979V0H46.1758V15.7629H49.0417V8.83319C49.0417 8.26418 49.1533 7.77646 49.3764 7.3768C49.6065 6.97714 49.9134 6.67231 50.3108 6.46232C50.7153 6.24555 51.1685 6.13717 51.6706 6.13717C52.4237 6.13717 53.0094 6.36071 53.4278 6.80779C53.8532 7.24809 54.0623 7.86452 54.0623 8.66384V15.7629H56.9283V8.23709C56.9283 7.28196 56.754 6.47587 56.4053 5.81202C56.0636 5.14818 55.5755 4.64691 54.9479 4.30144H54.9549Z"
          fill="#121010"
        />
        <path
          d="M70.2748 6.69263C69.8006 5.77138 69.1242 5.06012 68.2526 4.55207C67.3809 4.04403 66.3489 3.7934 65.1635 3.7934C63.9781 3.7934 62.946 4.0508 62.0744 4.55207C61.2028 5.05335 60.5264 5.77138 60.0452 6.69263C59.5711 7.61389 59.334 8.68417 59.334 9.90347C59.334 11.1228 59.5711 12.1931 60.0452 13.1075C60.5264 14.022 61.2028 14.7333 62.0744 15.2413C62.946 15.7494 63.9781 16 65.1635 16C66.3489 16 67.3809 15.7426 68.2526 15.2413C69.1242 14.7401 69.8006 14.022 70.2748 13.1075C70.7559 12.1931 70.993 11.1296 70.993 9.90347C70.993 8.67739 70.7489 7.61389 70.2748 6.69263ZM67.7645 11.8476C67.5553 12.4301 67.2275 12.8975 66.7952 13.2498C66.3629 13.5953 65.8259 13.7646 65.1774 13.7646C64.5289 13.7646 63.9711 13.5953 63.5318 13.2498C63.0995 12.8975 62.7717 12.4369 62.5556 11.8476C62.3464 11.265 62.2418 10.608 62.2418 9.88992C62.2418 9.17189 62.3464 8.51482 62.5556 7.92549C62.7717 7.33616 63.0995 6.86876 63.5318 6.51651C63.9711 6.16427 64.522 5.99492 65.1774 5.99492C65.8329 5.99492 66.3629 6.17104 66.7952 6.51651C67.2275 6.86876 67.5483 7.33616 67.7645 7.92549C67.9806 8.50804 68.0922 9.16511 68.0922 9.88992C68.0922 10.6147 67.9806 11.2583 67.7645 11.8476Z"
          fill="#121010"
        />
        <path d="M10.5573 5.54784L14.0718 0H10.7177L8.27014 3.94242C9.21151 4.21338 10.0134 4.78239 10.5573 5.54784Z" fill="#121010" />
        <path d="M5.87133 3.94242L3.43075 0H0.0627441L3.61205 5.52752C4.15595 4.77561 4.94391 4.21338 5.87133 3.94242Z" fill="#121010" />
        <path d="M10.5712 10.2015C10.0273 10.9738 9.2114 11.5563 8.26306 11.8273L10.7455 15.7629H14.1553L10.5712 10.2015Z" fill="#121010" />
        <path
          d="M3.60509 10.2286L0 15.7629H3.38892L5.87134 11.8205C4.93695 11.5495 4.14201 10.9805 3.59811 10.2218L3.60509 10.2286Z"
          fill="#121010"
        />
        <path
          d="M7.07764 10.7367C8.69896 10.7367 10.0133 9.45986 10.0133 7.88484C10.0133 6.30982 8.69896 5.03302 7.07764 5.03302C5.45631 5.03302 4.14197 6.30982 4.14197 7.88484C4.14197 9.45986 5.45631 10.7367 7.07764 10.7367Z"
          fill="#0F7DFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_2688">
          <rect width="71" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Logo;
