import React, { useState, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

const PdfViewer = ({ base64String }) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageScale, setPageScale] = useState<number>(1.0);
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  function zoomIn() {
    setPageScale(pageScale + 0.3);
  }

  function zoomOut() {
    setPageScale(pageScale - 0.3);
  }

  const zoomSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPageScale(parseFloat(event.target.value));
  };

  const handleMouseDown = (e: { preventDefault: () => void; clientY: React.SetStateAction<number>; clientX: React.SetStateAction<number>; }) => {
    e.preventDefault();
    setIsDragging(true);
    setStartY(e.clientY);
    setScrollTop(containerRef.current.scrollTop);
    setStartX(e.clientX);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e: { preventDefault: () => void; clientY: number; clientX: number; }) => {
    e.preventDefault();
    if (!isDragging) return;
    const diffY = e.clientY - startY;
    containerRef.current.scrollTop = scrollTop - diffY;
    const diffX = e.clientX - startX;
    containerRef.current.scrollLeft = scrollLeft - diffX;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div className="pdf-wrapper">
      <div className="pdf-tools">
        <div className="pdf-tools__inner">
          <span className="material-symbols-outlined pdf-zoom" onClick={zoomOut}>
            zoom_out
          </span>
          <select onChange={zoomSelection} className="pdf-select" defaultValue={1.0}>
            <option value={0.8}>80%</option>
            <option value={1.0}>100%</option>
            <option value={1.25}>125%</option>
            <option value={1.5}>150%</option>
            <option value={2.0}>200%</option>
          </select>
          <span className="material-symbols-outlined pdf-zoom" onClick={zoomIn}>
            zoom_in
          </span>
        </div>
      </div>
      <div
        className="pdf-document"
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        style={{
          cursor: isDragging ? 'grabbing' : 'grab',
        }}
      >
        <Document file={`data:application/pdf;base64,${base64String}`} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              scale={pageScale}
            />
          ))}
        </Document>
      </div>
    </div>
  );
};

export default PdfViewer;