import React from 'react';

function Button(props) {
  return (
    <button className={`btn btn--${props.size} btn--${props.background}`} id={props.id} onClick={props.onClick}>
      {props.title} <span className="icon material-symbols-outlined">{props.symbol}</span>
    </button>
  );
}

export default Button;
