import React from 'react';

function SideNavItem(props) {
  return (
    <li onClick={props.onClick}>
      <div className={`sidenav-item ${props.isActive ? 'active' : ''}`}>
        <span className="material-symbols-outlined">{props.icon}</span>
        {props.type}
      </div>
    </li>
  );
}

export default SideNavItem;
