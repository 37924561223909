import React, { useState } from 'react';
import { translate } from 'react-jhipster';
import axios from 'axios';
import { skipEntity, uploadEntity } from 'app/entities/xtraho_services/form/form.reducer';
import { useAppDispatch } from 'app/config/store';
import { errorToastDisplay, successToastDisplay } from 'app/shared/util/entity-utils';

function FileUpload() {
  const dispatch = useAppDispatch();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = event => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile, selectedFile.name);
      dispatch(uploadEntity(formData))
        .then(() => {
          setSelectedFile(null);
          successToastDisplay(translate('formSent'));
        })
        .catch(error => {
          errorToastDisplay(translate('formProblem'));
        });
    }
  };

  return (
    <form>
      <div className="mb-3">
        <label htmlFor="form-upload" className="form-label">
          {translate('inputFileFormLabel')}
        </label>
        <input className="form-control" type="file" onChange={handleFileChange} id="form-upload" />
      </div>
      {selectedFile && (
        <p>
          {translate('selectedFile')} {selectedFile.name}
        </p>
      )}
      <button className="btn btn--first ms-auto" onClick={handleSubmit}>
        {translate('upload')}
      </button>
    </form>
  );
}

export default FileUpload;
