import React from 'react';
import { translate } from 'react-jhipster';

const ArrayField = ({ array, parent }) => {
  if (!array || array.length === 0) {
    return <p>Aucun élément trouvé</p>;
  }
  return (
    <>
      {array.map((value: string, index: string) => (
        <div key={value + '_' + index} className="item-input__body">
          {Object.keys(value).map(key => (
            <div key={key} className="item-input__element">
              <label className="form-label" htmlFor={key}>
                {key} <span className="saved__label saved__label--success">{translate('savedSuccess')}</span>{' '}
                <span className="saved__label saved__label--error">{translate('savedError')}</span>
              </label>
              <input className="form-element" type="text" data-parent={parent} name={key} defaultValue={value[key].value} id={key} />
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default ArrayField;
