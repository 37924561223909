export interface IForm {
  id?: string;
  formId?: string;
  formImageContentType?: string | null;
  formImage?: string | null;
  confidenceScore?: number | null;
  status?: string;
}

export const defaultValue: Readonly<IForm> = {};
