import React from 'react';
import { useAppSelector } from 'app/config/store';
import { Translate } from 'react-jhipster';
import { percentageCalculation, dateFormatter } from 'app/shared/util/entity-utils';
import { LOCALE_FORMAT } from 'app/config/constants';
import FormItem from 'app/entities/xtraho_services/components/FormItem';
import LoadMore from 'app/entities/xtraho_services/components/LoadMore';

function ItemsListBody(props) {
  const loading = useAppSelector(state => state.xtrahoui.form.loading);
  const selectedStatus = useAppSelector(state => state.xtrahoui.form.selectedStatus);
  const locale = LOCALE_FORMAT;

  // Get the selected entity to get its ID
  const entity = useAppSelector(state => state.xtrahoui.form.entity);
  return (
    <div className="items-list__body">
      {loading && <Translate contentKey="xtrahoUiApp.xtrahoServicesForm.home.loading">Loading...</Translate>}
      {props.selectedForms && props.selectedForms.length > 0 ? (
        <>
          {props.selectedForms.map((form: { id: any; confidenceScore: any; createdDate; status }, i: any) => (
            <FormItem
              key={form.id}
              date={dateFormatter(form.createdDate, locale)}
              id={form.id}
              status={form.status}
              active={(entity ? entity.id : null) === form.id}
              percentage={percentageCalculation(form.confidenceScore)}
            />
          ))}
          <LoadMore />
        </>
      ) : (
        !loading && (
          <div className="alert alert-warning">
            <Translate contentKey="xtrahoUiApp.xtrahoServicesForm.home.notFound">No forms found</Translate>
            <div>
              <Translate contentKey="xtrahoUiApp.xtrahoServicesForm.home.withStatus">with status</Translate>"{selectedStatus}"
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default ItemsListBody;
