import React, { useEffect, useState } from 'react';
import './upload.css';
import FileUpload from './FileUpload';
import { translate } from 'react-jhipster';
import { Modal } from 'bootstrap';

function Upload() {
  const togglePopup = () => {
    const myModal = Modal.getOrCreateInstance('#myModal');
    myModal.toggle();
  };

  return (
    <div className="items-list__upload">
      <button className="btn btn--first btn--small btn--icon" type="button" onClick={togglePopup}>
        <span className="material-symbols-outlined">upload</span>
      </button>
      <div className="modal fade" id="myModal" data-bs-backdrop="static" aria-labelledby="myModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="myModalLabel">
                {translate('uploadModalTitle')}
              </h1>
              <button type="button" className="btn-close" aria-label="Close" onClick={togglePopup}></button>
            </div>
            <div className="modal-body">
              <FileUpload />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={togglePopup}>
                {translate('cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Upload;
