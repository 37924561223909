import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import SideNav from '../components/SideNav';
import FormDetail from 'app/entities/xtraho_services/components/FormDetail';
import ItemsList from '../components/ItemsList';
import { getNewOpenEntities } from './form.reducer';

export const Form = () => {
  const dispatch = useAppDispatch();

  const currentPage = useAppSelector(state => state.xtrahoui.form.currentPage);
  const numberOfItems = useAppSelector(state => state.xtrahoui.form.numberOfItems);

  const getAllOpenEntities = () => {
    dispatch(
      getNewOpenEntities({
        page: currentPage,
        size: numberOfItems,
        sort: `id,desc`,
      }),
    );
  };

  useEffect(() => {
    getAllOpenEntities();
  }, [currentPage]);

  return (
    <>
      <SideNav />
      <main className="main-content">
        <ItemsList />
        <FormDetail />
      </main>
    </>
  );
};

export default Form;
